import React, { useEffect, useState } from 'react';

import classes from './ISPRightSideBarEditCreateForm.module.css';
import { Text } from '@consta/uikit/Text';
import { InfoSystemType, OrganizationAndSubSystemType } from '../../../../types/types';
import { TextField } from '@consta/uikit/TextField';
import { Button } from '@consta/uikit/Button';
import Delimiter from '../../../../shared-front/Delimiter/Delimiter';
import { Combobox } from '@consta/uikit/Combobox';
import {
  createNewInfoSystemThunk,
  getInfoSystemsThunk,
  getOrganizationsAndSubSystemsForInfoSystemsThunk,
  selectorIsLoadingSubSystems,
  selectorSubSystems,
  setSearchValueInfoSystems,
  updateInfoSystemThunk,
} from '../../../../store/infoSystemsSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import { Loader } from '@consta/uikit/Loader';
import { transliterate } from '../../../../utils/Formatters/transliterate';
import { getQuerySearchValue } from '../../../../utils/getQuerySearchValue';
import { combineQuery } from '../../../../utils/combineQuery';
import { IspRightSideBarEnum } from '../../InfoSystemsPage';
import { CYRILLIC_PATTERN, START_PAGINATION_QUERY } from '../../../../utils/constants';
import BlueLinkOpenInNewTab from '../../../../Components/BlueLinkOpenInNewTab/BlueLinkOpenInNewTab';
import TextWithMark from '../../../../Components/TextWithMark/TextWithMark';
import iconInfoCircle from '../../../../assets/CatalogPage/iconInfoCircle.svg';
import CustomHoverToolTip from '../../../../Components/CustomHoverToolTip/CustomHoverToolTip';

type SubSystemItem = {
  label: string;
  id: number;
  uuid?: string;
  name?: string | null;
  full_name?: string | null;
};

const makeComboBoxItems = (subSystem?: OrganizationAndSubSystemType[]): SubSystemItem[] => {
  return subSystem
    ? subSystem.map((system, ind) => ({
        ...system,
        label: system.name ? system.name : '',
        id: ind,
      }))
    : [];
};

const getOrganizationAndSubSystemItem = (
  system: OrganizationAndSubSystemType | null | undefined,
  systems: OrganizationAndSubSystemType[] | undefined
) => {
  return system
    ? {
        ...system,
        label: system.name ? system.name : '',
        id: !!systems?.length ? systems?.findIndex((curSystem) => curSystem.uuid === system._uuid) : -1,
        uuid: system._uuid,
      }
    : null;
};

interface IISPRightSideBarEditCreateFormProps {
  infoSystem: InfoSystemType;
  closeSideBar: () => void;
  setRightSideBarMode?: (mode: IspRightSideBarEnum | undefined) => void;
}

const ISPRightSideBarEditCreateForm: React.FC<IISPRightSideBarEditCreateFormProps> = ({
  infoSystem,
  closeSideBar,
  setRightSideBarMode,
}) => {
  const dispatch = useAppDispatch();
  const subSystems = useAppSelector(selectorSubSystems);
  const isLoadingSubSystems = useAppSelector(selectorIsLoadingSubSystems);

  const isCreateNewInfoSystem = infoSystem._uuid === '-1';

  useEffect(() => {
    dispatch(getOrganizationsAndSubSystemsForInfoSystemsThunk());
  }, [dispatch]);

  const [en_nameValueError, setEn_NameValueError] = useState<string | undefined>(undefined);
  const [en_nameValue, setEn_NameValue] = useState<string>(infoSystem.en_name ? infoSystem.en_name : '');

  const handleChangeEn_NameValue = ({ value }: { value: string | null }) => {
    if (!!en_nameValue) {
      setEn_NameValueError(undefined);
    }
    setEn_NameValue(value ? value : '');
  };

  const [nameValueError, setNameValueError] = useState<string | undefined>(undefined);
  const [nameValue, setNameValue] = useState<string>(infoSystem.name ? infoSystem.name : '');
  const handleChangeNameValue = ({ value }: { value: string | null }) => {
    if (!!nameValue) {
      setNameValueError(undefined);
    }
    if (!!en_nameValue) {
      setEn_NameValueError(undefined);
    }
    setNameValue(value ? value : '');
    if (isCreateNewInfoSystem) {
      setEn_NameValue(
        value
          ? transliterate(value.toLowerCase())
              .replace(/\s/g, '_')
              .replace(/[^a-z0-9_-]/g, '')
          : ''
      );
    }
  };

  const [fullNameValue, setFullNameValue] = useState<string>(infoSystem.full_name ? infoSystem.full_name : '');
  const handleChangeFullNameValue = ({ value }: { value: string | null }) => {
    setFullNameValue(value ? value : '');
  };

  const [techNameEdmValue, setTechNameEdmValue] = useState<string>(
    infoSystem.tech_name_edm ? infoSystem.tech_name_edm : ''
  );
  const handleChangeTechNameEdmValue = ({ value }: { value: string | null }) => {
    setTechNameEdmValue(value ? value : '');
  };

  const [descriptionValue, setDescriptionValue] = useState<string>(
    infoSystem.description ? infoSystem.description : ''
  );
  const handleChangeDescriptionValue = ({ value }: { value: string | null }) => setDescriptionValue(value ? value : '');

  const [businessSystemValue, setBusinessSystemValue] = useState<SubSystemItem | null>(
    getOrganizationAndSubSystemItem(infoSystem.business_system, subSystems?.businessSystems)
  );
  const businessSystemItems = makeComboBoxItems(subSystems?.businessSystems);

  const [businessSolutionValue, setBusinessSolutionValue] = useState<SubSystemItem | null>(
    getOrganizationAndSubSystemItem(infoSystem.business_solution, subSystems?.businessSolutions)
  );
  const businessSolutionItems = makeComboBoxItems(subSystems?.businessSolutions);

  const [technicalSystemValue, setTechnicalSystemValue] = useState<SubSystemItem | null>(
    getOrganizationAndSubSystemItem(infoSystem.technical_system, subSystems?.technicalSystems)
  );
  const technicalSystemItems = makeComboBoxItems(subSystems?.technicalSystems);

  const [organizationValue, setOrganizationValue] = useState<SubSystemItem | null>(
    getOrganizationAndSubSystemItem(infoSystem.organization, subSystems?.organizations)
  );
  const organizationItems = makeComboBoxItems(subSystems?.organizations);

  const [blockValue, setBlockValue] = useState<SubSystemItem | null>(
    getOrganizationAndSubSystemItem(infoSystem.block, subSystems?.blocks)
  );
  const blockItems = makeComboBoxItems(subSystems?.blocks);

  const cancelButtonClick = () => {
    if (isCreateNewInfoSystem) {
      closeSideBar();
    } else {
      setRightSideBarMode && setRightSideBarMode(IspRightSideBarEnum.DisplaySystem);
    }
  };

  const onApplyClick = () => {
    if (!nameValue) {
      setNameValueError('Поле не заполнено');
    }
    if (!en_nameValue) {
      setEn_NameValueError('Поле не заполнено');
    }
    const isCyrillicInNameValue = CYRILLIC_PATTERN.test(en_nameValue);
    if (isCyrillicInNameValue) {
      setEn_NameValueError('Поле содержит символы на кирилице');
    }
    const isFirstLetterIsNumber = en_nameValue.slice(0, 1).match(/^\d/);
    if (isFirstLetterIsNumber) {
      setEn_NameValueError('Поле должно начинаться с буквы');
    }
    const hasDeprecatedSymbols = /[^a-z0-9_-]/.test(en_nameValue);
    if (hasDeprecatedSymbols) {
      setEn_NameValueError('Поле содержит запрещенные символы');
    }
    const hasWhitespace = /\s/.test(en_nameValue);
    if (hasWhitespace) {
      setEn_NameValueError('Поле содержит пробел');
    }
    if (
      nameValue &&
      en_nameValue &&
      !isCyrillicInNameValue &&
      !isFirstLetterIsNumber &&
      !hasDeprecatedSymbols &&
      !hasWhitespace
    ) {
      const infoSystemForSave = {
        _uuid: infoSystem._uuid,
        name: nameValue,
        tech_name_edm: en_nameValue,
        full_name: fullNameValue,
        slug: techNameEdmValue,
        description: descriptionValue ? descriptionValue : null,
        uuid_business_system: businessSystemValue ? businessSystemValue.uuid : null,
        uuid_business_solution: businessSolutionValue ? businessSolutionValue.uuid : null,
        uuid_technical_system: technicalSystemValue ? technicalSystemValue.uuid : null,
        uuid_organization: organizationValue ? organizationValue.uuid : null,
        uuid_block: blockValue ? blockValue.uuid : null,
      };
      if (isCreateNewInfoSystem) {
        dispatch(createNewInfoSystemThunk(infoSystemForSave)).then(() => {
          dispatch(setSearchValueInfoSystems(''));
          const searchValueQuery = getQuerySearchValue('');
          const requestQuery = combineQuery([searchValueQuery, START_PAGINATION_QUERY]);
          dispatch(getInfoSystemsThunk(requestQuery));
        });
        closeSideBar();
      } else {
        dispatch(updateInfoSystemThunk(infoSystemForSave)).then(() => {
          setRightSideBarMode && setRightSideBarMode(IspRightSideBarEnum.DisplaySystem);
        });
      }
    }
  };

  return (
    <div className={classes.container}>
      {isLoadingSubSystems ? (
        <Loader />
      ) : (
        <>
          <div className={classes.scrollContainer}>
            {!isCreateNewInfoSystem && (
              <div className={classes.section}>
                <Text className={classes.sectionTitle}>{'Код'}</Text>
                <Text className={classes.sectionValue}>{infoSystem.code ? infoSystem.code : '-'}</Text>
              </div>
            )}
            <div className={`${classes.section} ${classes.shortBottom}`}>
              <div className={classes.combineText}>
                <Text className={classes.sectionTitle}>{'Наименование'}</Text>
                <Text className={classes.requiredSign}>{'*'}</Text>
              </div>
              <div className={classes.textFieldContainer}>
                <TextField
                  onChange={handleChangeNameValue}
                  value={nameValue}
                  type="text"
                  placeholder={''}
                  size={'s'}
                  status={nameValueError ? 'alert' : undefined}
                />
                <Text className={classes.sectionAlert}>{nameValueError}</Text>
              </div>
            </div>

            <div className={`${classes.section} ${classes.shortBottom}`}>
              <div className={classes.combineText}>
                <Text className={classes.sectionTitle}>{'Полное наименование'}</Text>
              </div>
              <div className={classes.textFieldContainer}>
                <TextField
                  onChange={handleChangeFullNameValue}
                  value={fullNameValue}
                  type="text"
                  placeholder={''}
                  size={'s'}
                />
                <Text className={classes.sectionAlert}>{''}</Text>
              </div>
            </div>

            <div className={`${classes.section} ${classes.shortBottom}`}>
              <div className={classes.combineText}>
                <Text className={classes.sectionTitle}>{'Техническое наименование'}</Text>
                {isCreateNewInfoSystem && <Text className={classes.requiredSign}>{'*'}</Text>}
                <CustomHoverToolTip
                  title={
                    'Наименование интегрируемой системы в EDM. Значение может содержать латинские буквы, цифры, символы "-", "_" и должно начинаться с буквы. Не может содержать пробелы.'
                  }
                  zIndex={999}
                  direction={'downRight'}
                  className={classes.informer}
                  classNameTooltip={classes.tooltip}
                >
                  <img src={iconInfoCircle} className={classes.ico} alt={'infoBtn'} />
                </CustomHoverToolTip>
              </div>
              {isCreateNewInfoSystem ? (
                <div className={classes.textFieldContainer}>
                  <TextField
                    onChange={handleChangeEn_NameValue}
                    value={en_nameValue}
                    type="text"
                    placeholder={''}
                    size={'s'}
                    status={en_nameValueError ? 'alert' : undefined}
                  />
                  <Text className={classes.sectionAlert}>{en_nameValueError}</Text>
                </div>
              ) : (
                <>
                  <div className={classes.topMargin} />
                  <Text className={classes.sectionValue}>{en_nameValue ? en_nameValue : '-'}</Text>
                  <div className={classes.bottomMargin} />
                </>
              )}
            </div>

            <div className={`${classes.section} ${classes.shortBottom}`}>
              <div className={classes.combineText}>
                <Text className={classes.sectionTitle}>{'Краткое техническое наименование'}</Text>
                <CustomHoverToolTip
                  title={'Префикс системы используемый для разработки'}
                  zIndex={999}
                  direction={'downRight'}
                  className={classes.informer}
                  classNameTooltip={classes.tooltip}
                >
                  <img src={iconInfoCircle} className={classes.ico} alt={'infoBtn'} />
                </CustomHoverToolTip>
              </div>
              <div className={classes.textFieldContainer}>
                <TextField
                  onChange={handleChangeTechNameEdmValue}
                  value={techNameEdmValue}
                  type="text"
                  placeholder={''}
                  size={'s'}
                />
                <Text className={classes.sectionAlert}>{''}</Text>
              </div>
            </div>

            <div className={classes.section}>
              <Text className={classes.sectionTitle}>{'Описание'}</Text>
              <TextField
                onChange={handleChangeDescriptionValue}
                value={descriptionValue}
                type="textarea"
                rows={6}
                placeholder={''}
                size={'s'}
              />
            </div>

            <div className={classes.section}>
              <Text className={classes.sectionTitle}>{'Бизнес-система'}</Text>
              <Combobox
                items={businessSystemItems}
                value={businessSystemValue}
                onChange={({ value }) => {
                  setBusinessSystemValue(value);
                }}
                style={{ zIndex: 999 }}
              />
            </div>

            <div className={classes.section}>
              <Text className={classes.sectionTitle}>{'Бизнес-решение'}</Text>
              <Combobox
                items={businessSolutionItems}
                value={businessSolutionValue}
                onChange={({ value }) => {
                  setBusinessSolutionValue(value);
                }}
                style={{ zIndex: 999 }}
              />
            </div>

            <div className={classes.section}>
              <Text className={classes.sectionTitle}>{'Техническая система'}</Text>
              <Combobox
                items={technicalSystemItems}
                value={technicalSystemValue}
                onChange={({ value }) => {
                  setTechnicalSystemValue(value);
                }}
                style={{ zIndex: 999 }}
              />
            </div>

            <div className={classes.section}>
              <Text className={classes.sectionTitle}>{'Организация'}</Text>
              <Combobox
                items={organizationItems}
                value={organizationValue}
                onChange={({ value }) => {
                  setOrganizationValue(value);
                }}
                style={{ zIndex: 999 }}
              />
            </div>

            <div className={classes.section}>
              <Text className={classes.sectionTitle}>{'Блок'}</Text>
              <Combobox
                items={blockItems}
                value={blockValue}
                onChange={({ value }) => {
                  setBlockValue(value);
                }}
                style={{ zIndex: 999 }}
              />
            </div>
            {!isCreateNewInfoSystem && (
              <>
                <div className={classes.section}>
                  <Text className={classes.sectionTitle}>{'EDM'}</Text>
                  <div className={classes.buttonsGroup}>
                    {infoSystem.link_edm ? (
                      <BlueLinkOpenInNewTab linkUrl={infoSystem.link_edm} linkTitle={'Смотреть в EDM'} />
                    ) : (
                      <div>{'-'}</div>
                    )}
                  </div>
                </div>
                <div className={classes.section}>
                  <Text className={classes.sectionTitle}>{'Интегрирация с хранилищем Дата-платформы'}</Text>
                  <div className={classes.buttonsGroup}>
                    <TextWithMark
                      isComplete={infoSystem.integration_dwh}
                      textDone={'Интегрировано с хранилищем'}
                      textNotDone={'Не интегрировано с хранилищем'}
                    />
                  </div>
                </div>
                <div className={classes.section}>
                  <Text className={classes.sectionTitle}>{'Сканирование метаданных'}</Text>
                  <div className={classes.buttonsGroup}>
                    <TextWithMark
                      isComplete={infoSystem.scan_metadata}
                      textDone={'Сканировано'}
                      textNotDone={'Не сканировано'}
                    />
                  </div>
                </div>
              </>
            )}
            <div className={classes.grayBottom} />
          </div>
          <Delimiter />
          <div className={classes.bottomButtons}>
            <Button
              size={'s'}
              view={'ghost'}
              label={'Отменить'}
              style={{ width: '193px' }}
              onClick={cancelButtonClick}
            />
            <Button
              size={'s'}
              label={isCreateNewInfoSystem ? 'Создать' : 'Сохранить'}
              style={{ width: '193px' }}
              onClick={onApplyClick}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ISPRightSideBarEditCreateForm;
