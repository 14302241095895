import React from 'react';

import classes from './DbCommonInfoTab.module.css';
import CollapseBlock from '../../../../../../Components/CollapseBlock/CollapseBlock';
import CardsContentBlock from '../../../../../../Components/CardsContentBlock/CardsContentBlock';
import SystemCard from '../../../../../../Components/SystemCard/SystemCard';
import { BookmarkRdbCdbType, BookmarkType } from '../../../../../../types/types';
import { formatDateDDMMYYYY } from '../../../../../../utils/formatDateDDMMYYYY';
import CardInputLongDescription from '../../../../../../Components/CardInputLongDescription/CardInputLongDescription';
import { useBookmarksView } from '../../../../../../hooks/useBookmarksView';

interface IRdbCommonInfoTabProps {
  bookmark: BookmarkType;
}

const DbCommonInfoTab: React.FC<IRdbCommonInfoTabProps> = ({ bookmark }) => {
  const data = bookmark.data as BookmarkRdbCdbType | undefined;

  const {
    isOpenBookmarkCollapseBlockMain,
    isOpenBookmarkCollapseBlockAdditional,
    isOpenBookmarkCollapseTextField,
    textFieldValue,
    onIsOpenChangeCollapseBlockMain,
    onIsOpenChangeCollapseBlockAdditional,
    onIsOpenChangeCollapseTextField,
    onChangeTextFieldValue,
  } = useBookmarksView({ bookmarkId: bookmark.hash, bookmarkDescription: bookmark.data?.description });

  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>
        <CollapseBlock
          title={'Основное'}
          isOpen={isOpenBookmarkCollapseBlockMain}
          onIsOpenChange={onIsOpenChangeCollapseBlockMain}
        >
          <CardInputLongDescription
            bookmark={bookmark}
            textFieldValue={textFieldValue}
            isModeEdit={isOpenBookmarkCollapseTextField}
            onIsModeEditChange={onIsOpenChangeCollapseTextField}
            onChangeTextFieldValue={onChangeTextFieldValue}
          />
          <CardsContentBlock
            title={'Описание из системы-источник'}
            description={data?.comment ? data.comment : '-'}
            className={classes.description}
          />
        </CollapseBlock>

        <CollapseBlock
          title={'Дополнительно'}
          isOpen={isOpenBookmarkCollapseBlockAdditional}
          onIsOpenChange={onIsOpenChangeCollapseBlockAdditional}
        >
          <CardsContentBlock title={'Кодировка'} description={data?.encoding ? data.encoding : '-'} />
          <CardsContentBlock title={'Движок'} description={data?.engine ? data.engine : '-'} />
          <CardsContentBlock
            title={'Изменено в системе - источнике'}
            description={
              data?.altered_in_source_system_at ? formatDateDDMMYYYY(new Date(data.altered_in_source_system_at)) : '-'
            }
          />
        </CollapseBlock>
      </div>
      <div className={classes.rightColumn}>
        <SystemCard
          systemUuid={data?.system._uuid}
          systemName={data?.system.name}
          systemSourceIco={data?.source_icon}
          systemSourceType={data?.source_type}
        />
      </div>
    </div>
  );
};

export default DbCommonInfoTab;
