import { instanceAxios } from './instanceAxios';
import {
  ConnectionConnectorType,
  ConnectionSettingsItemType,
  ConnectionSourceType,
  ConnectionType,
  InfoSystemType,
} from '../types/types';
import { CheckNewConnectionType } from './serverResponse';

export type CheckNewConnectionParamsType = {
  system: string;
  source_type: string;
  connector_type: string;
  user?: string;
  password?: string;
  host?: string;
  port?: number;
  instance?: string;
  url?: string;
  uuid?: string;
  version?: string;
};

export type CreateNewConnectionParamsType = CheckNewConnectionParamsType & {
  connectionName?: string;
  connectionDescription?: string;

  general_settings?: ConnectionSettingsItemType[];
  asset_settings?: { [key: string]: ConnectionSettingsItemType[] }[] | undefined;

  scanSchedule?: string;
  previewSchedule?: string;
};

export const createNewConnectionApi = {
  async getInfoSystemsDictionary() {
    const response = await instanceAxios.get<{ data: InfoSystemType[] }>(`/dictionaries/systems`);
    return response.data.data;
  },

  async getSourceTypesDictionary() {
    const response = await instanceAxios.get<{ data: ConnectionSourceType[] }>(`/source-types`);
    return response.data.data;
  },

  async getConnectorTypesDictionary() {
    const response = await instanceAxios.get<{ data: ConnectionConnectorType[] }>(`/connector-types`);
    return response.data.data;
  },

  async checkNewConnection(params: CheckNewConnectionParamsType) {
    const response = await instanceAxios.post<CheckNewConnectionType>(`/connections/check`, params);
    return response.data;
  },
  async checkConnectionByUuid(uuid: string) {
    const response = await instanceAxios.post<CheckNewConnectionType>(`/connections/check-existing/`, { uuid });
    return response.data;
  },
  async createNewConnection(params: CreateNewConnectionParamsType) {
    const response = await instanceAxios.post<{ data: ConnectionType }>(`/connections`, params);
    return response.data.data;
  },
};
