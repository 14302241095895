import React from 'react';

import classes from './InfoSystemSelector.module.css';
import RightSideBarFormSelector from '../../../../../../../Components/RightSideBarFormSelector/RightSideBarFormSelector';
import {
  getInfoSystemsDictionaryThunk,
  selectorActiveInfoSystem,
  selectorInfoSystems,
  selectorIsLoadingInfoSystem,
  selectorIsRequiredInfoSystem,
  setActiveInfoSystem,
} from '../../../../../../../store/createNewConnectionSlice';
import { ItemComboboxType } from '../../../../../../../types/types';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/hooks';

interface IInfoSystemSelectorProps {}

const InfoSystemSelector: React.FC<IInfoSystemSelectorProps> = () => {
  const dispatch = useAppDispatch();
  const infoSystems = useAppSelector(selectorInfoSystems);
  const isLoadingInfoSystem = useAppSelector(selectorIsLoadingInfoSystem);
  const isRequiredInfoSystem = useAppSelector(selectorIsRequiredInfoSystem);
  const activeInfoSystem = useAppSelector(selectorActiveInfoSystem);
  const infoSystemsItems = infoSystems?.map((system) => ({
    label: system.name ? system.name : '',
    id: system._uuid,
    _uuid: system._uuid,
  }));

  const activeInfoSystemItemIndex = infoSystemsItems?.findIndex((item) => item._uuid === activeInfoSystem?._uuid);
  const activeInfoSystemValue =
    activeInfoSystemItemIndex !== undefined && infoSystemsItems
      ? infoSystemsItems[activeInfoSystemItemIndex]
      : undefined;

  const onLoadInfoSystems = () => {
    dispatch(getInfoSystemsDictionaryThunk());
  };

  const onChangeInfoSystems = (value: ItemComboboxType | null) => {
    dispatch(setActiveInfoSystem(value));
  };

  return (
    <div className={classes.container}>
      <RightSideBarFormSelector
        value={activeInfoSystemValue}
        items={infoSystemsItems}
        onChange={onChangeInfoSystems}
        required={true}
        isRequired={isRequiredInfoSystem}
        placeholder={'Выбрать'}
        label={'Интегрируемая система'}
        isLoading={isLoadingInfoSystem}
        onLoadItems={onLoadInfoSystems}
      />
    </div>
  );
};

export default InfoSystemSelector;
